import React from 'react';

const Tick = () => (
  <svg viewBox="0 0 29 22.5">
    <g>
      <path
        fill="#588d4e"
        d="M11,14,4.72,7.46,0,11.7H0L10.78,22.5,29,4.24,24.84,0Z"
      />
    </g>
  </svg>
);

export default Tick;
