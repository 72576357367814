import React from 'react';

const Logo = ({ width, color }) => (
  <svg viewBox="0 0 470.55 214.66" width={width}>
    <g stoke={color} fill={color}>
      <path d="M287.72,67.94c5.82,2.69,12.6,9.86,12.6,9.86a4.09,4.09,0,0,1-.11,5.7l-6.85,6.71a3.13,3.13,0,0,1-5-.57s-3.18-5-7-7.29-8.6-3.51-14.42-3.51q-7.93,0-12.24,3a9.14,9.14,0,0,0-4.3,7.87q0,4.5,4.37,7.15t15.75,4.63q11.91,2.25,18.92,5.56t10.26,8.27A22.17,22.17,0,0,1,303,127.67a22.07,22.07,0,0,1-4.57,14,28.82,28.82,0,0,1-12.7,9.06,51,51,0,0,1-18.59,3.18q-14.43,0-23.62-4c-6.13-2.69-12.18-9.29-12.18-9.29a4.71,4.71,0,0,1-.14-6l5.72-6.83a3.08,3.08,0,0,1,5,.1s4.57,5.94,8.81,8,9.48,3.11,15.75,3.11c5.64,0,9.95-.88,12.9-2.65a8.33,8.33,0,0,0,4.43-7.54c0-2.38-1.39-4.37-4.17-6s-7.74-3.09-14.89-4.5q-12.3-2.51-19.65-6t-10.65-8.47A22.12,22.12,0,0,1,231.1,91.4a24.68,24.68,0,0,1,4.23-14.09,28,28,0,0,1,12.37-9.86,47.31,47.31,0,0,1,19.12-3.57Q279,63.93,287.72,67.94Z" />
      <path d="M378.86,137.88a4,4,0,0,1,4,4v6.69a4,4,0,0,1-4,4h-57.5a4,4,0,0,1-4-4V69.23a4,4,0,0,1,4-4h57.5a4,4,0,0,1,4,4v6.69a4,4,0,0,1-4,4H339.62a4,4,0,0,0-4,4v13.7a4,4,0,0,0,4,4H368.8a4,4,0,0,1,4,4v6a4,4,0,0,1-4,4H339.62a4,4,0,0,0-4,4v14.23a4,4,0,0,0,4,4Z" />
      <path d="M470.55,75.92a4,4,0,0,1-4,4H445.3a4,4,0,0,0-4,4v64.65a4,4,0,0,1-4,4H427a4,4,0,0,1-4-4V83.92a4,4,0,0,0-4-4H397.66a4,4,0,0,1-4-4V69.23a4,4,0,0,1,4-4h68.88a4,4,0,0,1,4,4v6.69Z" />
      <path d="M292,203.46l-3.87.24c-.68,0-1-.15-1-.46s.45-.6,1.35-.89a2.88,2.88,0,0,0,1.6-.9,1.92,1.92,0,0,0,.24-.89V176a5.07,5.07,0,0,0-.58-2.06,2.45,2.45,0,0,0-1.64-1.48c-.26-.06-.39-.27-.39-.6s.11-.54.34-.61a41.88,41.88,0,0,0,5.61-1.94l.05-.05h0c.19,0,.29.15.29.44l-.24,2.9a1.05,1.05,0,0,0,.05.44,12.44,12.44,0,0,1,3.36-2.06,10.7,10.7,0,0,1,4.48-1.14,7,7,0,0,1,5.76,2.76,10,10,0,0,1,2.18,6.39,11.61,11.61,0,0,1-3.22,8,10,10,0,0,1-7.67,3.56,9.32,9.32,0,0,1-2.61-.46,5.83,5.83,0,0,1-2.23-1.09c-.13,0-.19.82-.19,2.47v9.05a1.11,1.11,0,0,0,.56,1,5.49,5.49,0,0,0,.82.41,4.29,4.29,0,0,0,.8.19c1.16.29,1.74.64,1.74,1.06s-.36.44-1.06.44Zm1.75-26.56,0,3.1q0,2.33.12,4.62a4.84,4.84,0,0,0,1.28,3.39c.77.73,2.23,1.09,4.35,1.09a6,6,0,0,0,3.87-1.79,7.09,7.09,0,0,0,1.72-2.68,11.35,11.35,0,0,0,.7-4.21,11.93,11.93,0,0,0-.7-4.28,5.81,5.81,0,0,0-1.81-2.66,7.55,7.55,0,0,0-4.23-1.4,6.45,6.45,0,0,0-4,1.4C294.29,174,293.87,175.16,293.78,176.9Z" />
      <path d="M317.63,189.81l-3.92.24c-.65,0-1-.15-1-.46s.29-.56.87-.75,1-.33,1.16-.41a1.66,1.66,0,0,0,.6-.51,1.57,1.57,0,0,0,.31-1V176.5q0-3-2-3.48c-.26-.06-.39-.27-.39-.6s.11-.54.34-.61a8,8,0,0,0,2.71-1c1.1-.71,1.82-1.17,2.18-1.38a3.54,3.54,0,0,0,.53-.34s0,0,.12,0,.15.11.22.34c0,.87,0,1.71-.07,2.52a3.68,3.68,0,0,0,0,1.21q.07,0,.27-.24a8.2,8.2,0,0,1,.87-1,6.83,6.83,0,0,1,4.45-2.08,3,3,0,0,1,1.67.44,1.32,1.32,0,0,1,.65,1.16,2.9,2.9,0,0,1-.6,1.6q-.6.87-1.14.87a4.41,4.41,0,0,1-1.55-.44,4.45,4.45,0,0,0-1.5-.44,3.39,3.39,0,0,0-2.39.92,4.14,4.14,0,0,0-1,3.14v9.53c0,.87.9,1.56,2.61,2.08.74.26,1.11.54,1.11.85s-.32.46-1,.46Z" />
      <path d="M329.19,180.89a11,11,0,0,1,3.12-7.6,9.92,9.92,0,0,1,17.44,6.61,10.84,10.84,0,0,1-2.88,7.47,10.23,10.23,0,0,1-8.05,3.22,9.43,9.43,0,0,1-9.63-9.7Zm9.77-9.51q-5.81,0-5.81,8.37a11.24,11.24,0,0,0,1.94,6.55,5.63,5.63,0,0,0,4.74,2.83q3.63,0,5-2.66a12.78,12.78,0,0,0,1-5.61,11.56,11.56,0,0,0-1.94-6.6A5.7,5.7,0,0,0,339,171.38Z" />
      <path d="M369.49,171.29l1.55,0a9.93,9.93,0,0,1,1,0c.29,0,.44.33.44.9s-.15.85-.44.85a9.81,9.81,0,0,0-2,.1l-.39.17a1.5,1.5,0,0,1-.34.12,8.06,8.06,0,0,0-.19,2.23,9.62,9.62,0,0,1-2.32,6.39,7.28,7.28,0,0,1-5.81,2.76,5.36,5.36,0,0,1-2.61-.58q-2.13,1.65-2.13,2.37,0,1.54,4.64,2,6.28.58,7.38,1.06a15.53,15.53,0,0,1,2,1,3.27,3.27,0,0,1,1.45,3,9.52,9.52,0,0,1-3.51,7.09A10.68,10.68,0,0,1,361,204a14.17,14.17,0,0,1-6.34-1.31,4.38,4.38,0,0,1-2.64-4.21,4.46,4.46,0,0,1,.75-2.59,8.45,8.45,0,0,1,2.52-2.2,7.84,7.84,0,0,0,2.49-2.23,8.64,8.64,0,0,1-3-1.23,2.74,2.74,0,0,1-1.35-2.39,2.38,2.38,0,0,1,1.11-1.79c.19-.13.5-.32.92-.58a6.41,6.41,0,0,0,1.94-1.64,4.7,4.7,0,0,1-1.84-1.26,7.09,7.09,0,0,1-2.37-5.37,6.48,6.48,0,0,1,2.61-5.25,9.3,9.3,0,0,1,6-2.1,9.55,9.55,0,0,1,2.85.53A18.2,18.2,0,0,0,369.49,171.29Zm-14.08,25.1a5.32,5.32,0,0,0,1.67,4A6.31,6.31,0,0,0,361.6,202a7.18,7.18,0,0,0,5-1.94,6.32,6.32,0,0,0,2.13-4.89,2.42,2.42,0,0,0-1.19-2,7.27,7.27,0,0,0-2.83-1.14,35.68,35.68,0,0,0-4.6-.46H359a23,23,0,0,0-2.42,2.23A4.2,4.2,0,0,0,355.41,196.39Zm10.3-19.23A6.87,6.87,0,0,0,364.4,173a3.9,3.9,0,0,0-3.24-1.79,3.54,3.54,0,0,0-3.1,1.81,7.34,7.34,0,0,0,.12,8.1,3.74,3.74,0,0,0,3.17,1.94,3.68,3.68,0,0,0,3.12-1.79A7,7,0,0,0,365.71,177.16Z" />
      <path d="M378.78,189.81l-3.92.24c-.65,0-1-.15-1-.46s.29-.56.87-.75,1-.33,1.16-.41a1.66,1.66,0,0,0,.6-.51,1.57,1.57,0,0,0,.31-1V176.5q0-3-2-3.48c-.26-.06-.39-.27-.39-.6s.11-.54.34-.61a8,8,0,0,0,2.71-1c1.1-.71,1.82-1.17,2.18-1.38a3.54,3.54,0,0,0,.53-.34s0,0,.12,0,.15.11.22.34c0,.87,0,1.71-.07,2.52a3.68,3.68,0,0,0,0,1.21q.08,0,.27-.24a8.2,8.2,0,0,1,.87-1,6.83,6.83,0,0,1,4.45-2.08,3,3,0,0,1,1.67.44,1.32,1.32,0,0,1,.65,1.16,2.9,2.9,0,0,1-.6,1.6q-.6.87-1.14.87a4.41,4.41,0,0,1-1.55-.44,4.45,4.45,0,0,0-1.5-.44,3.39,3.39,0,0,0-2.39.92,4.14,4.14,0,0,0-1,3.14v9.53c0,.87.9,1.56,2.61,2.08.74.26,1.11.54,1.11.85s-.32.46-1,.46Z" />
      <path d="M394.26,174.72l.05.68c0,.29-.46.65-1.38,1.06a5.85,5.85,0,0,1-1.86.63c-.32,0-.48-.15-.48-.44a4.2,4.2,0,0,1,1.67-3.12A19.32,19.32,0,0,1,396,171a8,8,0,0,1,3.75-1.09,3.78,3.78,0,0,1,2.88,1.26,4.19,4.19,0,0,1,1.11,3.05l-.24,10.45v.29c0,2.29.58,3.43,1.74,3.43a2.75,2.75,0,0,0,1.94-.68.35.35,0,0,1,.48,0,.52.52,0,0,1,.29.46v0a4.61,4.61,0,0,1-1.5,1.52,4,4,0,0,1-2.39.9,3.09,3.09,0,0,1-2-.63,4.33,4.33,0,0,1-1.14-1.26c-.23-.42-.37-.63-.44-.63s-.42.21-.87.63a8,8,0,0,1-2,1.26,6.81,6.81,0,0,1-2.95.63,4.28,4.28,0,0,1-2.95-1.21,3.52,3.52,0,0,1-1.33-2.61q0-2.91,3.53-4.35l4.93-2a2.69,2.69,0,0,0,1.11-.68,2.82,2.82,0,0,0,.24-1.26l.1-3v-.1a3.24,3.24,0,0,0-.8-2.18,2.4,2.4,0,0,0-1.84-.92,4,4,0,0,0-2,.51,3,3,0,0,0-1.16.94A2.11,2.11,0,0,0,394.26,174.72Zm-.05,11a2.53,2.53,0,0,0,.77,2,2.7,2.7,0,0,0,1.89.7,4.32,4.32,0,0,0,2.61-1.31,2.64,2.64,0,0,0,.58-1.94l.1-2.37c0-.68-.11-1-.44-1a1.67,1.67,0,0,0-.68.15C395.82,183,394.21,184.25,394.21,185.7Z" />
      <path d="M416.07,169.45l-.24,3.1c0,.32,0,.52.1.58a11,11,0,0,1,7.64-3.24,4.36,4.36,0,0,1,4.5,2.9c.16.36.33.53.51.53s.52-.3,1-.89a7.48,7.48,0,0,1,2.71-1.72,10.44,10.44,0,0,1,4.14-.82,4,4,0,0,1,3.51,2,8.47,8.47,0,0,1,1.31,4.72v10.3a1.27,1.27,0,0,0,.58,1.26q.63.28,1.62.63c.66.23,1,.49,1,.8s-.32.46-1,.46l-3.82-.24-4,.24c-.65,0-1-.15-1-.46s.37-.59,1.11-.85l.19,0c1.06-.32,1.68-.76,1.84-1.31a2.61,2.61,0,0,0,.05-.48V176.76a4.88,4.88,0,0,0-1.16-3.19,3.42,3.42,0,0,0-2.71-1.4,6.49,6.49,0,0,0-4.06,1.16,4.29,4.29,0,0,0-1.5,3.63v10a1.11,1.11,0,0,0,.15.7.94.94,0,0,1,.19.29.47.47,0,0,0,.24.22,1.28,1.28,0,0,1,.27.17.84.84,0,0,0,.34.15.93.93,0,0,1,.31.12,1.81,1.81,0,0,0,.68.19c.74.26,1.11.54,1.11.85s-.32.46-1,.46l-4-.24-4,.24c-.65,0-1-.15-1-.46s.45-.61,1.35-.9a3.07,3.07,0,0,0,1.62-.89,1.79,1.79,0,0,0,.27-.9V176.77a4.88,4.88,0,0,0-1.16-3.19,3.42,3.42,0,0,0-2.71-1.4,6.45,6.45,0,0,0-4,1.14,4.34,4.34,0,0,0-1.48,3.65v10a1.29,1.29,0,0,0,.56,1.23,3.68,3.68,0,0,0,.75.36q1.89.48,1.89,1.11c0,.29-.32.44-1,.44l-3.92-.24-3.92.24c-.65,0-1-.15-1-.46s.27-.55.8-.73.84-.27.92-.29a3.19,3.19,0,0,0,.51-.22,1.22,1.22,0,0,0,.48-.34,1.65,1.65,0,0,0,.48-1.11v-10.4a5.9,5.9,0,0,0-.39-2.37,2,2,0,0,0-1.5-1.11c-.26-.06-.39-.27-.39-.6s.11-.54.34-.61a13.31,13.31,0,0,0,4.6-2.23,5.63,5.63,0,0,1,.73-.44C415.78,169.13,415.91,169.22,416.07,169.45Z" />
      <path d="M211.13,79.91H174v21.7h30.18v14H174v22.23h36.27a107.83,107.83,0,0,0,.88-58Z" />
      <path d="M155.72,65.23h50.37a107.35,107.35,0,1,0-1.41,87.33h-49V65.23Zm-32.5,87.33-20.51-33.48H91.21v5.86L75.4,112.13,91.21,99.32v5.86h13.22q7.93,0,11.71-3.11t3.77-9.59q0-6.48-3.77-9.59t-11.71-3.11H69.75V65.22h37.07q15,0,23.36,7.08t8.4,19.92q0,9.39-4.63,15.75a25.52,25.52,0,0,1-13.23,9.13l23,35.46Z" />
    </g>
  </svg>
);

export default Logo;
